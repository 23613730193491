<template>
  <!-- Export Excel Dialog -->
  <v-dialog
    v-model="exportDocumentOpen.open"
    width="200"
  >
    <v-card>
      <v-card-title
        class="text-h5 grey lighten-2"
        primary-title
      >
        File Type
      </v-card-title>

      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="success"
          @click="exportCSV(exportDocumentOpen.tableID)"
        >
          CSV
        </v-btn>
        <v-btn
          color="primary"
          @click="exportXLS(exportDocumentOpen.tableID)"
        >
          XLS
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ExportDocument',
  props: {
    exportDocumentOpen: {
      type: Object,
      required: true,
    },
  },
  methods: {
    downloadCSV(csv, filename) {
      let csvFile;
      let downloadLink;

      // eslint-disable-next-line prefer-const
      csvFile = new Blob([csv], { type: 'text/csv;charset=UTF-8' });
      // eslint-disable-next-line prefer-const
      downloadLink = document.createElement('a');
      downloadLink.download = filename;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);
      downloadLink.click();
    },
    exportCSV() {
      const csv = [];
      const { rows } = document.querySelectorAll('table')[0];

      for (let i = 0; i < rows.length; i += 1) {
        const row = [];
        const cols = rows[i].querySelectorAll('td, th');

        for (let j = 0; j < cols.length; j += 1) row.push(cols[j].innerText);

        csv.push(row.join(','));
      }

      // Download CSV file
      this.downloadCSV(csv.join('\n'), `${this.exportDocumentOpen.tableID}csv_data.csv`);
      this.exportDocumentOpen.open = false;
    },
    exportXLS(tableID) {
      let downloadLink;
      const dataType = 'application/vnd.ms-excel;text/csv;charset=UTF-8';
      const tableSelect = document.getElementById(tableID);
      const tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
      // { type: ' type: "text/csv;charset=UTF-8"' }
      let filename;
      // eslint-disable-next-line prefer-const
      filename = filename ? `${filename}.xls` : `${tableID}-excel_data.xls`;
      // eslint-disable-next-line prefer-const
      downloadLink = document.createElement('a');
      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        const blob = new Blob(['\ufeff', tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        downloadLink.href = `data:${dataType}, ${tableHTML}`;
        downloadLink.download = filename;
        downloadLink.click();
      }
      this.exportDocumentOpen.open = false;
    },
  },
};
</script>

<style scoped>

</style>
