import request from '@/api/utils';

 
export function getInverterList(formdata = {}, method = 'post') {
  const url = '/panel/inverter/list';
  return request.Api(url, formdata, method);
}

export function newInverterCreate(formdata = {}, method = 'post') {
  const url = '/panel/inverter/create';
  return request.Api(url, formdata, method);
}


export function inverterHourly(formdata = {}, method = 'post') {
  const url = "/panel/inverter/hourcomparison";
  return request.Api(url, formdata, method);
}

export function inverterMinute(formdata = {}, method = 'post') {
  const url = "/panel/inverter/minutecomparison";
  return request.Api(url, formdata, method);
}


export function inverterDaily(formdata = {}, method = 'post') {
  const url = "/panel/inverter/daily";
  return request.Api(url, formdata, method);
}

export function inverterMonthly(formdata = {}, method = 'post') {
  const url = "/panel/inverter/monthly";
  return request.Api(url, formdata, method);
}

export function inverterYearly(formdata = {}, method = 'post') {
  const url = "/panel/inverter/yearly";
  return request.Api(url, formdata, method);
}