import store from '../../store/index';
import chartOptions from '../chart/chart_options';
import common from '../component/common.js';


const get_minute_data = (_rawData, selectMeasure, text, chartTypeModel, santralModel, measures) =>
  new Promise((resolve) => {
    const sortType = 'datetimestamp';
    const res = _rawData.data.sort((a, b) => {
      if (a[sortType] < b[sortType]) return -1;
      if (a[sortType] > b[sortType]) return 1;
      return 0;
    });
    const tempTimeData = {};
    res.forEach((item) => {
      let hh;
      let
        mm;
      item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;
      item.MinuteGrup < 10 ? mm = `0${item.MinuteGrup}` : mm = `${item.MinuteGrup}`;
      const date = `${hh}:${mm}`;

      if (!tempTimeData[item.Time]) {
        tempTimeData[item.Time] = {};
      }
      tempTimeData[item.Time][date] = item;
    });

    const desserts = [];
    const dessert = {};
    const dateList = [];
    const yAxis = [];
    const series = [];
    const result = {};
    const {
      santralList,
    } = store.getters;

    let index = 0;
    const step_time = santralList[santralModel].dataLoggerTimeInterval;

    Object.keys(tempTimeData).forEach((item) => {
      const minute = 0;
      const hour = 5;
      const tempData = [];

      for (let i = hour; i <= 20; i++) {
        for (let x = minute; x < 60; x += step_time) {
          let h;
          let
            m;
          i < 10 ? h = `0${i}` : h = `${i}`;
          x < 10 ? m = `0${x}` : m = `${x}`;
          const time = `${h}:${m}`;
          const obj = {};
          obj.date = time;

          if (dateList.indexOf(time) < 0) {
            dateList.push(time);
          }

          let tempVal = 0;

          if (tempTimeData.hasOwnProperty(item)) {
            if (tempTimeData[item].hasOwnProperty(time)) {
              const tempValue = tempTimeData[item][time];
              tempVal = common.setMeasures(selectMeasure, tempValue);
            }
          }

          tempData.push(tempVal);

          obj[item] = tempVal;

          dessert[time] = {
            ...dessert[time],
            ...obj,
          };
        }
      }

      const obj = {
        name: `${item} ${selectMeasure.text} ( ${selectMeasure.unit} )`,
        data: tempData,
        type: chartTypeModel,
      };

      yAxis.push({
        type: 'value',
        name: selectMeasure.value,
      });

      const yaxis = {
        type: 'value',
        name: `${selectMeasure.text} ( ${selectMeasure.unit} )`,
        show: false,
      };
      if (index > 0) {
        yaxis.show = false;
      }

      yAxis.push(yaxis);
      series.push(obj);
      index += 1;
    });
    Object.keys(dessert).forEach((key) => {
      desserts.push(dessert[key]);
    });

    const options = chartOptions.line_options_multies_1(text, measures, dateList,
      series, common.colors(), yAxis);

    result.options = options;
    result.desserts = desserts;

    resolve(result);

  });


const get_hourly_data = (_rawData, selectMeasure, text, chartTypeModel, measures) =>
  new Promise((resolve) => {

    const tempTimeData = {};
    _rawData.data.forEach((item) => {
      let hh;

      item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;
      const date = `${hh}`;

      if (!tempTimeData[item.Time]) {
        tempTimeData[item.Time] = {};
      }
      tempTimeData[item.Time][date] = item;
    });


    const desserts = [];
    const dessert = {};
    const dateList = [];
    const yAxis = [];
    const series = [];
    const result = {};

    let index = 0;


    Object.keys(tempTimeData).forEach((item) => {

      const hour = 5;
      const tempData = [];

      for (let i = hour; i <= 20; i++) {

        let h;

        i < 10 ? h = `0${i}` : h = `${i}`;

        const time = `${h}`;
        const obj = {};
        obj.date = time;

        if (dateList.indexOf(time) < 0) {
          dateList.push(time);
        }

        let tempVal = 0;

        if (tempTimeData.hasOwnProperty(item)) {
          if (tempTimeData[item].hasOwnProperty(time)) {
            const tempValue = tempTimeData[item][time];
            tempVal = common.setMeasures(selectMeasure, tempValue);
          }
        }

        tempData.push(tempVal);

        obj[item] = tempVal;
        dessert[time] = {
          ...dessert[time],
          ...obj,
        };

      }

      const obj = {
        name: `${item} ${selectMeasure.text} ( ${selectMeasure.unit} )`,
        data: tempData,
        type: chartTypeModel,
      };

      yAxis.push({
        type: 'value',
        name: selectMeasure.value,
      });

      const yaxis = {
        type: 'value',
        name: `${selectMeasure.text} ( ${selectMeasure.unit} )`,
        show: false,
      };
      if (index > 0) {
        yaxis.show = false;
      }

      yAxis.push(yaxis);
      series.push(obj);
      index++;
    });
    Object.keys(dessert).forEach((key) => {
      desserts.push(dessert[key]);
    });

    const options = chartOptions.line_options_multies_1(text, measures, dateList,
      series, common.colors(), yAxis);

    result.options = options;
    result.desserts = desserts;

    resolve(result);

  });

const get_daily_data = (_rawData, selectMeasure, text, chartTypeModel) => new Promise((
  resolve) => {

  const tempTimeData = {};
  const timeList = [];

  _rawData.data.forEach((item) => {
    let mm;
    let
      dd;

    item.Month < 10 ? mm = `0${item.Month}` : mm = `${item.Month}`;
    item.Day < 10 ? dd = `0${item.Day}` : dd = `${item.Day}`;

    const date = `${item.Year}-${mm}-${dd}`;
    const kk = `${item.Year}-${item.Month}`;

    if (!tempTimeData[kk]) {
      tempTimeData[kk] = {};
    }
    if (!tempTimeData[kk][item.Day]) {
      tempTimeData[kk][item.Day] = {};
    }
    item.date = date;
    tempTimeData[kk][item.Day] = item;
  });

  const desserts = [];
  const dessert = {};
  const dateList = [];
  const series = [];
  const result = {};

  const yAxis = [];
  let index = 0;

  Object.keys(tempTimeData).forEach((item) => {
    dateList.push(item);
    const tempData = [];

    for (let i = 1; i < 32; i++) {
      if (timeList.indexOf(i) < 0) {
        timeList.push(i);
      }

      const obj = {};
      let tempVal = 0;

      if (tempTimeData.hasOwnProperty(item)) {
        if (tempTimeData[item].hasOwnProperty(i)) {
          const tempValue = tempTimeData[item][i];
          tempVal = common.setMeasures(selectMeasure, tempValue);
        }
      }

      tempData.push(tempVal);
      obj[item] = tempVal;
      obj.date = i;

      dessert[i] = {
        ...dessert[i],
        ...obj,
      };
    }

    const obj = {
      name: `${item} ${selectMeasure.text}(${selectMeasure.unit})`,
      data: tempData,
      type: chartTypeModel,
    };

    const yaxis = {
      type: 'value',
      name: `${selectMeasure.text}(${selectMeasure.unit})`,
    };
    if (index > 0) {
      yaxis.show = false;
    }

    yAxis.push(yaxis);
    index++;
    series.push(obj);
  });

  Object.keys(dessert).forEach((key) => {
    desserts.push(dessert[key]);
  });

  const options = chartOptions.line_options_multies_1(text, dateList,
    timeList, series, common.colors(), yAxis);

  result.options = options;
  result.desserts = desserts;

  resolve(result);

});


const get_monthly_data = (_rawData, selectMeasure, text, chartTypeModel, yearModel) =>
  new Promise((resolve) => {

    const tempTimeData = {};

    _rawData.data.forEach((item) => {
      let mm;

      item.Month < 10 ? mm = `0${item.Month}` : mm = `${item.Month}`;

      const date = `${item.Year}-${mm}`;

      if (!tempTimeData[date]) {
        tempTimeData[date] = {};
      }

      item.date = date;
      tempTimeData[date] = item;
    });

    const desserts = [];
    const dessert = {};
    const dateList = [];
    const series = [];
    const result = {};

    const yAxis = [];
    let index = 0;

    for (let i = 1; i < 13; i++) {
      dateList.push(i);
    }


    Object.keys(yearModel).forEach((k) => {

      const tempData = [];

      let mm;
      for (let i = 1; i < 13; i++) {

        i < 10 ? mm = `0${i}` : mm = `${i}`;

        const date = `${yearModel[k]}-${mm}`;

        const obj = {};
        let tempVal = 0;

        if (tempTimeData.hasOwnProperty(date)) {
          tempVal = common.setMeasures(selectMeasure, tempTimeData[date]);
        }

        tempData.push(tempVal);
        obj[yearModel[k]] = tempVal;
        obj.date = i;

        dessert[i] = {
          ...dessert[i],
          ...obj,
        };
      }

      const obj = {
        name: `${yearModel[k]} ${selectMeasure.text}(${selectMeasure.unit})`,
        data: tempData,
        type: chartTypeModel,
      };

      const yaxis = {
        type: 'value',
        name: `${selectMeasure.text}(${selectMeasure.unit})`,
      };
      if (index > 0) {
        yaxis.show = false;
      }

      yAxis.push(yaxis);
      index++;
      series.push(obj);
    });


    Object.keys(dessert).forEach((key) => {
      desserts.push(dessert[key]);
    });
    const options = chartOptions.line_options_multies_1(text, dateList,
      dateList, series, common.colors(), yAxis);

    result.options = options;
    result.desserts = desserts;

    resolve(result);

  });

const get_yearly_data = (_rawData, selectMeasure, text, chartTypeModel, yearList) =>
  new Promise((resolve) => {

    const tempTimeData = {};
    const desserts = [];
    const dateList = [];
    const series = [];
    const result = {};
    const yAxis = [];
    let index = 0;

    _rawData.data.forEach((item) => {

      if (!tempTimeData[item.Year]) {
        tempTimeData[item.Year] = {};
      }

      item.date = item.Year;
      tempTimeData[item.Year] = item;
    });


    const tempData = [];

    Object.keys(yearList).forEach((k) => {

      const obj = {};
      let tempVal = 0;
      if (tempTimeData.hasOwnProperty(yearList[k])) {
        tempVal = common.setMeasures(selectMeasure, tempTimeData[yearList[k]]);
      }
      tempData.push(tempVal);
      obj['val'] = tempVal;
      obj.date = yearList[k];

      desserts.push(obj);
    });


    const objs = {
      name: ` ${selectMeasure.text}(${selectMeasure.unit})`,
      data: tempData,
      type: chartTypeModel,
    };

    const yaxis = {
      type: 'value',
      name: `${selectMeasure.text}(${selectMeasure.unit})`,
    };
    if (index > 0) {
      yaxis.show = false;
    }

    yAxis.push(yaxis);
    index++;
    series.push(objs);

    const options = chartOptions.line_options_multies_1(text, yearList,
      yearList, series, common.colors(), yAxis);

    result.options = options;
    result.desserts = desserts;

    resolve(result);

  });
export default {
  get_minute_data,
  get_hourly_data,
  get_daily_data,
  get_monthly_data,
  get_yearly_data
};
